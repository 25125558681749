<template>
	<div class="com_layer_normal layer_search" style="width: 600px; height: 500px">
		<div class="lay_header">
			<h1>{{ info.title }}</h1>
			<a class="btn_close" title="닫기" v-on:click="close"><span class="ic_set ic_close"></span></a>
		</div>
		<!--content-->
		<div class="lay_body vue_component">
			<div class="com_form">
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">구분</span>
						</label>
						<div class="form_data cell">
							<div class="com_slt">
								<select class="slt_normal" v-model="managerInfo.type">
									<option v-for="type in types" :key="type.id" :value="type.value">{{ type.name }}</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">담당자명</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="managerInfo.name" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">이메일</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="managerInfo.email" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">유선전화</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input
									v-doms-inputValidate:onlyNumber=""
									class="ipt_normal"
									type="text"
									v-model="managerInfo.contact"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">휴대전화번호</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input
									@input="managerInfo.phone = onlyNumber($event)"
									v-doms-inputValidate:onlyNumber=""
									class="ipt_normal"
									type="text"
									v-model="managerInfo.phone"
								/>
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<div class="form_data cell">
							<div class="com_ck">
								<label
									>개인정보수집이용동의(필수)<input
										:disabled="true"
										type="checkbox"
										v-model="managerInfo.agreePrivacyInfoTerm" /><span class="checkmark"></span
								></label>
							</div>
							<span v-if="privacyInfoTerm" class="txt" @click="openTermsLayer(privacyInfoTerm)"><a>약관보기</a></span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<div class="form_data cell">
							<div class="com_ck">
								<label
									>마케팅동의(선택)<input type="checkbox" v-model="managerInfo.agreeMarketingTerm" /><span
										class="checkmark"
									></span
								></label>
							</div>
							<span class="txt" v-if="marketingTerm" @click="openTermsLayer(marketingTerm)"><a>약관보기</a></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!--//content end-->
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" v-on:click="save" :disabled="disable">저장</button>
				<button class="minor" v-on:click="close">닫기</button>
			</div>
		</div>
	</div>
</template>

<script>
import termsTemplate from '@/components/apply/termsDetail.vue';
import { getManager, addManager, updateManager } from '@/api/manager';
export default {
	props: ['info', 'privacyInfoTerm', 'marketingTerm'],
	data() {
		return {
			disable: false,
			openTerms: {},
			types: this.$constants['MANAGER_TYPE'],
			componentStatus: this.info.componentStatus,
			managerInfo: {
				id: this.info.managerId,
				partnerId: this.info.type == 'PARTNER' ? this.info.typeId : '',
				customerId: this.info.type == 'CUSTOMER' ? this.info.typeId : '',
				type: 'ORDER',
				name: '',
				email: '',
				contact: '',
				phone: '',
				agreePrivacyInfoTerm: true,
				agreePrivacyInfoTermDate: !this.info.managerId ? this.$moment().format('YYYY-MM-DD') : '',
				agreeMarketingTerm: true,
				agreeMarketingTermDate: !this.info.managerId ? this.$moment().format('YYYY-MM-DD') : '',
			},
			originalTerms: {
				agreeMarketingTerm: false,
			},
		};
	},
	mounted() {
		if (this.info.managerId) this.getManager();
	},
	methods: {
		openTermsLayer(item) {
			if (!item) {
				return;
			}
			this.openTerms = item;
			this.$store.commit('initAgreeTerm');
			this.$modal.show(
				termsTemplate,
				{
					info: item,
				},
				{
					width: '800px',
					height: '715px',
					draggable: true,
				},
				{
					closed: this.closeTermsLayer,
				},
			);
		},
		async closeTermsLayer() {
			const agreeTerm = this.$store.getters.getAgreeTerm;
			console.log(agreeTerm);
			console.log(this.openTerms);
			if (agreeTerm) {
				if (this.openTerms.termsType == 'TERMS_OF_PRIVACY') {
					this.managerInfo.agreePrivacyInfoTerm = true;
				}
				if (this.openTerms.termsType == 'TERMS_OF_MARKETING') {
					this.managerInfo.agreeMarketingTerm = true;
				}
			}
		},
		onlyNumber(event) {
			return isNaN(event.target.value) ? '' : event.target.value;
		},
		async getManager() {
			try {
				const { data } = await getManager(this.info.managerId);
				this.managerInfo = data.data;
				this.originalTerms.agreeMarketingTerm = this.managerInfo.agreeMarketingTerm;
			} catch (e) {
				console.log(e);
			}
		},
		getValidateRule() {
			return [
				{
					data: this.managerInfo.name,
					name: '담당자명',
					validateType: 'required',
				},
				{
					data: this.managerInfo.email,
					name: '이메일',
					validateType: 'required',
				},
				{
					data: this.managerInfo.contact,
					name: '유선전화',
					validateType: 'required',
				},
				{
					data: this.managerInfo.phone,
					name: '휴대전화',
					validateType: 'required',
				},
			];
		},
		async save() {
			try {
				await this.$ms.validate.dataValidate(this.getValidateRule());
				this.disable = true;

				if (this.managerInfo.id) {
					if (this.isChangeAgree(this.managerInfo.agreeMarketingTerm, this.originalTerms.agreeMarketingTerm)) {
						this.managerInfo.agreeMarketingTermDate = this.$moment().format('YYYY-MM-DD');
					}
					await updateManager(this.managerInfo);
				} else {
					await addManager(this.managerInfo);
				}
				this.disable = false;
				this.close();
			} catch (e) {
				console.log(e);
			}
		},
		isChangeAgree(agree, newAgree) {
			if (agree) {
				return false;
			}
			return newAgree && !agree;
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>
<style>
.com_layer_normal {
	z-index: 99;
}
</style>
