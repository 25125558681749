<template>
	<div class="com_layer_normal layer_search" style="width: 520px">
		<div class="lay_header">
			<h1>회사정보 수정</h1>
			<a class="btn_close" title="닫기" @click="close">
				<span class="ic_set ic_close"></span>
			</a>
		</div>
		<!--content-->
		<div class="lay_body vue_component">
			<div class="com_form">
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">상호(법인명)</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.corporateName" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">대표자명</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.ceoName" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">사업자등록번호</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="w_160px">
								{{ companyInfo.businessLicenseNum }}
								<!-- <input
									class="ipt_normal"
									type="text"
									v-model="companyInfo.businessLicenseNum"
								/> -->
								<LicenseConfirm
									v-model="businessInfoConfirm"
									:businessNum="companyInfo.businessLicenseNum"
									v-if="false"
								>
									<span class="label_state ongoing confirm_business"> 사업자 진위 확인 </span>
								</LicenseConfirm>
							</div>
							<!-- <div v-if="confirm">
								<span class="sys_msg em">사업자 확인</span>
							</div> -->
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">법인번호</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.businessNum" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">산업분류</span>
						</label>
						<div class="form_data cell">
							<div class="com_slt">
								<select class="slt_normal" v-model="companyInfo.industryCategory">
									<option value="" selected="" disabled="">선택하세요.</option>
									<option v-for="item in $constants.INDUSTRY_CATEGORY" :key="item.id" :value="item.name">
										{{ item.name }}
									</option>
								</select>
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">업태</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.businessCondition" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">종목</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.businessType" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">사업장주소</span>
							<span class="asterisk">*</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.companyAddress" />
							</div>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">대표전화</span>
						</label>
						<div class="form_data cell">
							<div class="com_ipt">
								<input class="ipt_normal" type="text" v-model="companyInfo.repNumber" />
							</div>
						</div>
					</div>
				</div>
				<p>※ 세금계산서 등 정확한 증빙 발급을 위해 입력 필수값을 다시 한번 확인해주세요.</p>
			</div>
		</div>
		<!--//content end-->
		<div class="lay_bottom">
			<div class="wrap_function">
				<button class="major" @click="saveCompanyInfo">저장</button>
				<button class="minor" @click="close">취소</button>
			</div>
		</div>
	</div>
</template>

<script>
import LicenseConfirm from '@/components/businessLicense/LicenseConfirm';
export default {
	props: ['info'],
	components: {
		LicenseConfirm,
	},
	data() {
		return {
			companyInfo: this.info,
			businessInfoConfirm: true,
			confirm: true,
		};
	},
	watch: {
		businessInfoConfirm() {
			this.confirm = this.businessInfoConfirm;
		},
	},
	methods: {
		changeBussinessInfo() {
			this.confirm = false;
			this.businessInfoConfirm = false;
		},
		close() {
			this.$emit('close');
		},
		async saveCompanyInfo() {
			try {
				if (!this.confirm) {
					this.$ms.dialog.alert('사업자 진위확인은 필수 입니다.');
					return;
				}

				await this.$ms.validate.dataValidate(this.getValidateRule());
				const param = {
					id: this.companyInfo.id,
					companyInfo: {
						corporateName: this.companyInfo.corporateName,
						ceoName: this.companyInfo.ceoName,
						businessLicenseNum: this.companyInfo.businessLicenseNum,
						businessNum: this.companyInfo.businessNum,
						industryCategory: this.companyInfo.industryCategory,
						businessCondition: this.companyInfo.businessCondition,
						businessType: this.companyInfo.businessType,
						companyAddress: this.companyInfo.companyAddress,
						repNumber: this.companyInfo.repNumber,
					},
				};
				this.$ms.dialog.confirm(
					'고객사 정보 수정',
					'수정하시겠습니까?',
					() => {
						this.$store.dispatch('UPDATECUSTOMER', param);
						this.$toasted.show('수정했습니다.');
						this.close();
					},
					() => {},
				);
			} catch (e) {
				console.log(e);
			}
		},

		getValidateRule() {
			const valid = [
				{
					data: this.companyInfo.corporateName,
					name: '상호(법인명)',
					validateType: 'required',
				},
				{
					data: this.companyInfo.ceoName,
					name: '대표자명',
					validateType: 'required',
				},
				{
					data: this.companyInfo.businessLicenseNum,
					name: '사업자등록번호',
					validateType: 'required',
				},
				{
					data: this.companyInfo.businessCondition,
					name: '업태',
					validateType: 'required',
				},
				{
					data: this.companyInfo.businessType,
					name: '종목',
					validateType: 'required',
				},
				{
					data: this.companyInfo.companyAddress,
					name: '사업장주소',
					validateType: 'required',
				},
			];
			return valid;
		},
	},
};
</script>

<style scoped>
.com_form .row .form_tit {
	width: 115px !important;
}
.confirm_business {
	margin-top: 4px;
	margin-left: 10px;
	cursor: pointer;
}
</style>
