import { manager } from './index';

export const getManagerList = async (data) => {
	return await manager.get(`/api/doms/managers`, { params: data });
};

export const getManager = async (managerId) => {
	return await manager.get(`/api/doms/manager/${managerId}`);
};

export const addManager = async (data) => {
	return await manager.post(`/api/doms/manager`, JSON.stringify(data));
};

export const updateManager = async (data) => {
	return await manager.put(`/api/doms/manager`, JSON.stringify(data));
};

export const deleteManager = async (managerId) => {
	return await manager.delete(`/api/doms/manager/${managerId}`);
};
