<template>
	<div class="com_list">
		<table class="com_tb_data tb_manager">
			<thead>
				<tr>
					<th class="part active"><span class="txt">구분</span><button class="sort"></button></th>
					<th class="name"><span class="txt">담당자명</span><button class="sort"></button></th>
					<th class="mail flex"><span class="txt">이메일</span><button class="sort"></button></th>
					<th class="phone"><span class="txt">유선전화</span><button class="sort"></button></th>
					<th class="phone"><span class="txt">휴대전화번호</span><button class="sort"></button></th>
					<th class="agree"><span class="txt">개인정보수집 이용동의</span><button class="sort"></button></th>
					<th class="agree"><span class="txt">마케팅 동의</span><button class="sort"></button></th>
					<th class="mgmt">
						<span class="manager_button" @click="addManager">
							<span class="ic_set ic_addlist"></span>
							<span class="txt">담당자추가</span> </span
						><button class="sort"></button>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr class="null_data" v-if="managerList.length == 0">
					<td colspan="8">
						<span class="txt">데이터가 없습니다.</span><br />
						<span class="txt"><a v-on:click="addManager()">담당자 추가</a></span>
					</td>
				</tr>
				<!--read-->
				<tr v-for="(manager, index) in managerList" :key="index" @click="updateManager(manager.id)">
					<td class="part">
						<div class="com_slt">
							<select :disabled="true" class="slt_normal" v-model="manager.type">
								<option :value="'OPERATION'">운영 담당</option>
								<option :value="'PAYMENT'">결제 담당</option>
								<option :value="'ENGINEER'">엔지니어 담당</option>
								<option :value="'ORDER'">주문 담당</option>
							</select>
						</div>
					</td>
					<td class="name">
						<div class="wrap_nowrap">
							<span class="txt">{{ manager.name }}</span>
						</div>
					</td>
					<td class="mail flex">
						<span class="txt">{{ manager.email }}</span>
					</td>
					<td class="phone">
						<span class="txt">{{ manager.contact }}</span>
					</td>
					<td class="phone">
						<span class="txt">{{ manager.phone }}</span>
					</td>
					<td class="agree">
						<div class="com_ck">
							<label :for="index"
								><input :disabled="true" :id="index" type="checkbox" v-model="manager.agreePrivacyInfoTerm" /><span
									class="checkmark"
								></span>
								<template v-if="manager.agreePrivacyInfoTerm"
									>동의 / {{ getFormateDate(manager.agreePrivacyInfoTermDate) }}</template
								>
								<template v-else> 미동의 </template>
							</label>
						</div>
					</td>
					<td class="agree">
						<div class="com_ck">
							<label
								><input :disabled="true" type="checkbox" v-model="manager.agreeMarketingTerm" /><span
									class="checkmark"
								></span>
								<template v-if="manager.agreeMarketingTerm"
									>동의 / {{ getFormateDate(manager.agreeMarketingTermDate) }}</template
								>
								<template v-else>미동의 </template>
							</label>
						</div>
					</td>
					<td class="mgmt">
						<button class="del" title="삭제" @click.stop="checkDeleteManager(index, manager.id)">
							<i class="ic_set ic_del"></i>
						</button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { getManagerList, deleteManager } from '@/api/manager';
import popupTemplate from '@/components/apply/user/store/ManagerModal.vue';
export default {
	name: 'ManagerList',
	props: {
		termsModels: {},
		type: {
			type: String,
			required: true,
			default: 'CUSTOMER',
		},
		typeId: {},
		value: {},
	},
	data() {
		const privacyTerms = this.termsModels.filter((obj) => obj.termsType == 'TERMS_OF_PRIVACY' && obj.useForm);
		let privacyInfoTerm = null;
		if (privacyTerms && privacyTerms.length > 0) {
			privacyInfoTerm = privacyTerms[0];
		}

		const marketingTerms = this.termsModels.filter((obj) => obj.termsType == 'TERMS_OF_MARKETING' && obj.useForm);
		let marketingTerm = null;
		if (marketingTerms && marketingTerms.length > 0) {
			marketingTerm = marketingTerms[0];
		}

		return {
			types: this.$constants['MANAGER_TYPE'],
			managerList: this.value,
			managerInfo: {},
			componentStatus: '',
			privacyInfoTerm,
			marketingTerm,
		};
	},
	watch: {
		managerList() {
			this.$emit('input', this.managerList);
		},
	},
	mounted() {
		if (this.typeId == undefined || this.typeId == '') {
			this.componentStatus = 'CREATE';
			return;
		} else {
			this.componentStatus = 'UPDATE';
			this.getManagerList();
		}
	},
	methods: {
		getFormateDate(val) {
			const date = new Date(val);
			const year = date.getFullYear();
			let month = date.getMonth() + 1;
			month = month >= 10 ? month : '0' + month;
			let day = date.getDate();
			day = day >= 10 ? day : '0' + day;
			return year + '-' + month + '-' + day;
		},
		async getManagerList() {
			try {
				const { data } = await getManagerList({
					type: this.type,
					id: this.typeId,
				});
				this.managerList = data.data;
			} catch (e) {
				console.log(e);
			}
		},
		async checkDeleteManager(idx, id) {
			this.$ms.dialog.confirm(
				'담당자 삭제',
				'삭제된 담당자 정보는 재 조회되지 않습니다.\n담당자 정보를 삭제하시겠습니까?',
				() => {
					this.$ms.dialog.alert('담당자 삭제', '담당자 정보가 삭제되었습니다.', () => {});
					this.deleteManager(idx, id);
				},
				() => {
					return;
				},
			);
		},
		async deleteManager(idx, id) {
			try {
				this.managerList.splice(idx, 1);
				if (this.componentStatus != 'CREATE') {
					await deleteManager(id);
				}
			} catch (e) {
				console.log(e);
			}
		},
		addManager() {
			if (this.componentStatus == 'CREATE') {
				const today = this.$moment().format('YYYY-MM-DD');
				this.managerList.push({
					type: 'OPERATION',
					name: '',
					email: '',
					phone: '',
					agreePrivacyInfoTerm: false,
					agreePrivacyInfoTermDate: today,
					agreeMarketingTerm: false,
					agreeMarketingTermDate: today,
				});
			} else {
				this.formPopup(this.type, this.typeId, this.componentStatus, '');
			}
		},
		updateManager(managerId) {
			this.formPopup(this.type, this.typeId, this.componentStatus, managerId);
		},
		formPopup(type, typeId, componentStatus, managerId) {
			var data = {
				title: '담당자',
				type: type,
				typeId: typeId,
				componentStatus: componentStatus,
				managerId: managerId,
			};
			this.$modal.show(
				popupTemplate,
				{
					info: data,
					privacyInfoTerm: this.privacyInfoTerm,
					marketingTerm: this.marketingTerm,
				},
				{
					width: '600px',
					height: '465px',
					draggable: true,
				},
				{
					closed: this.popupClosed,
				},
			);
		},
		popupClosed() {
			this.getManagerList();
		},
	},
};
</script>

<style>
.manager_button {
	cursor: pointer;
}
</style>
