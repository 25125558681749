<template>
	<div v-if="membership !== null">
		<div class="container wrap_form view wrap_info_company">
			<h2>
				회사정보 &nbsp;
				<!-- <span
					class="label_state ongoing edit_position"
					v-if="isSAAS()"
					@click="openEditLayer()"
				>
					편집
				</span> -->
			</h2>

			<div class="com_form">
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">상호명</span>
						</label>
						<div class="form_data cell">
							<span class="txt tit" v-if="customer.companyInfo.corporateName">{{
								customer.companyInfo.corporateName
							}}</span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">대표자명</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.ceoName }}</span>
						</div>
						<label class="form_tit cell">
							<span class="txt">대표번호</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.repNumber }}</span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">사업자 등록번호</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.businessLicenseNum }}</span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">법인번호</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.businessNum }}</span>
						</div>

						<label class="form_tit cell">
							<span class="txt">산업분류</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.industryCategory }}</span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">업태</span>
						</label>
						<div class="form_data cell">
							<span class="txt">
								<template v-if="customer.companyInfo.businessCondition">{{
									customer.companyInfo.businessCondition
								}}</template>
							</span>
						</div>

						<label class="form_tit cell">
							<span class="txt">종목</span>
						</label>
						<div class="form_data cell">
							<span class="txt"
								><template v-if="customer.companyInfo.businessType">{{ customer.companyInfo.businessType }}</template>
								<template v-else>-</template>
							</span>
						</div>
					</div>
				</div>
				<div class="wrap_row">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">사업장 주소</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ customer.companyInfo.companyAddress }}</span>
						</div>
					</div>
				</div>
				<div class="wrap_row" v-if="isSAAS()">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">멤버십</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ membership.baseChannelInfoModel.baseChannelModel.name }} </span>
						</div>
					</div>
				</div>
				<div class="wrap_row" v-if="isSAAS()">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">서비스 시작일</span>
						</label>
						<div class="form_data cell">
							<span class="txt">{{ membership.membershipBaseInfoModel.startDate }} </span>
						</div>

						<template
							v-if="
								this.membership.membershipChargeType !== 'FREE' &&
								membership.membershipBaseInfoModel.expirationDate < '2099-12-31'
							"
						>
							<label class="form_tit cell">
								<span class="txt">서비스 만료일</span>
							</label>
							<div class="form_data cell">
								<span class="txt">
									{{ membership.membershipBaseInfoModel.expirationDate }}
								</span>
							</div>
						</template>
					</div>
				</div>
				<div class="wrap_row" v-if="payInfoVisible && isSAAS()">
					<div class="row">
						<label class="form_tit cell">
							<span class="txt">결제 정보</span>
						</label>
						<div class="form_data cell">
							<span class="txt">자동연장</span>
							<span class="com_toggle_normal">
								<label class="switch">
									<!--
										:disabled="membership.autoExtension !== true"-->
									<input type="checkbox" v-model="autoExtension" :disabled="true" />
									<span class="slider round" v-on:click="autoExtensionChange(autoExtension)"></span>
								</label>
							</span>
							<div v-if="autoExtension">
								<p class="em">
									{{ nextPayDateStr }}에 서비스 연장금액 {{ monthlyFee | currency }}원(VAT별도)이 결제될 예정입니다.
								</p>

								<!--카드정보-->
								<div class="wrap_creditcard">
									<!--결제카드-->
									<div class="com_creditcard" v-for="(item, index) in autoExtensionCardList" :key="index">
										<div class="header">
											<!--<a
												class="btn_close"
												title="카드 삭제"
												v-on:click="deleteAutoExtensionCard"
											>
												<span class="ic_set ic_close"></span>
											</a>-->
										</div>
										<div class="body">
											<!--<div class="tit">BC카드</div>-->
											<div class="item">
												<span class="tit">CARD NUMBERS</span>
												<span class="data">{{ item.cardNumberDisplay }}</span>
											</div>
											<div class="item expiry">
												<span class="tit">EXPIRY DATE</span>
												<span class="data">**/**</span>
											</div>
											<div class="item cvc">
												<span class="tit">CVC</span>
												<span class="data">***</span>
											</div>
										</div>
									</div>
									<!--카드등록-->
									<div class="com_creditcard create" v-if="autoExtensionCardList.length == 0">
										<div class="body null_data">
											<p class="txt">등록된 카드가 없습니다.</p>
											<span class="sub">자동연장 사용 시<br />사용할 카드를 등록 해주세요.</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<p class="txt">※ 회사정보 변경을 원하시는 경우 1577-3019로 문의바랍니다.</p>
		</div>
		<!--//container-->
		<div class="container wrap_form view wrap_info_company">
			<h2>사업자등록증</h2>
			<div class="form_data">
				<div class="row">
					<AttachComponent :isEdit="false"></AttachComponent>
					<br />
				</div>
			</div>
		</div>
		<!--container-->
		<div
			class="container wrap_list"
			v-if="
				membership.baseChannelInfoModel.multiSiteCollection &&
				membership.baseChannelInfoModel.multiSiteCollection.siteInfos.length > 0
			"
		>
			<h2>멀티사이트 정보</h2>
			<div class="com_list">
				<table class="com_tb_data tb_multisite">
					<thead>
						<tr>
							<th class="subject"><span class="txt">사이트 그룹명</span></th>
							<th class="subject">
								<span class="txt">도메인명</span>
							</th>
							<th class="subject">
								<span class="txt">사이트명</span>
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(siteInfo, index) in membership.baseChannelInfoModel.multiSiteCollection.siteInfos" :key="index">
							<td
								class="item"
								:rowspan="membership.baseChannelInfoModel.multiSiteCollection.siteInfos.length"
								v-if="index == 0"
							>
								<span class="txt">{{ membership.baseChannelInfoModel.multiSiteCollection.groupName }}</span>
							</td>
							<td class="subject">
								<span class="txt">{{ siteInfo.domain }}</span>
							</td>
							<td class="subject">
								<span class="txt">{{ siteInfo.siteName }}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<p class="txt">※ 멀티사이트 정보 변경 및 해지를 원하시는 경우 1577-3019로 문의바랍니다.</p>
		</div>
		<!--//container-->

		<!--container-->
		<div class="container wrap_list">
			<h2>담당자 정보</h2>
			<manager-info
				:termsModels="getTermsModels()"
				:type="'CUSTOMER'"
				:typeId="customer.id"
				v-model="customer.managerList"
			></manager-info>
		</div>

		<div class="container wrap_list" v-if="membership.partnerSimpleModel.salesType !== 'RESELLER'">
			<h2>세금계산서 담당자 및 계좌정보</h2>
			<div class="com_list">
				<table class="com_tb_data tb_multisite">
					<thead>
						<tr>
							<th class="subject">
								<span class="txt">세금계산서 담당자명</span>
							</th>
							<th class="subject">
								<span class="txt">세금계산서 담당자 이메일</span>
							</th>
							<th class="subject"><span class="txt">계좌번호</span></th>
							<th class="subject">
								<span class="txt">금융기관명</span>
							</th>
							<th class="subject">
								<span class="txt">예금주</span>
							</th>
							<th class="agree"><span class="txt">개인정보수집 이용동의</span></th>
							<th class="subject"><span class="txt">편집</span></th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="item" @click="accountInfoEdit = true">
								<div class="com_ipt w_160px">
									<input
										:disabled="!accountInfoEdit"
										type="text"
										class="ipt_normal"
										v-model="customer.companyInfo.taxInvoiceManager.taxInvoiceManagerName"
									/>
								</div>
							</td>
							<td class="item" @click="accountInfoEdit = true">
								<div class="com_ipt w_240px">
									<input
										:disabled="!accountInfoEdit"
										type="text"
										class="ipt_normal"
										v-model="customer.companyInfo.taxInvoiceManager.taxInvoiceEmail"
									/>
								</div>
							</td>
							<td class="item" @click="accountInfoEdit = true">
								<div class="com_ipt w_240px">
									<input
										:disabled="!accountInfoEdit"
										type="text"
										class="ipt_normal"
										v-model="customer.companyInfo.taxInvoiceManager.refundAccountNumber"
									/>
								</div>
							</td>
							<td class="item" @click="accountInfoEdit = true">
								<div class="com_ipt w_240px">
									<input
										:disabled="!accountInfoEdit"
										type="text"
										class="ipt_normal"
										v-model="customer.companyInfo.taxInvoiceManager.bankName"
									/>
								</div>
							</td>
							<td class="item" @click="accountInfoEdit = true">
								<div class="com_ipt w_160px">
									<input
										:disabled="!accountInfoEdit"
										type="text"
										class="ipt_normal"
										v-model="customer.companyInfo.taxInvoiceManager.refundAccountOwnerName"
									/>
								</div>
							</td>
							<td class="agree">
								<div class="com_ck" v-on:click="showTaxInvoicePrivacyTerm()">
									<label for="1">
										<input :checked="true" disabled="disabled" id="1" type="checkbox" />
										<span class="checkmark"></span>
										동의 / {{ membership.membershipBaseInfoModel.registerDate }}
									</label>
								</div>
							</td>
							<td class="item">
								<span class="label_state in" v-if="!accountInfoEdit" @click="accountInfoEdit = true">편집하기</span>
								<span
									class="label_state draft"
									v-if="accountInfoEdit"
									@click="
										accountInfoEdit = false;
										saveAccountInfo();
									"
									>저장하기</span
								>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 22.09.22 이후 개발 예정
		<div v-if="hasSubCustomerList()" class="container wrap_form view wrap_info_company">
			<h2>계열사 및 관계사</h2>
			<sub-customer v-model="subCustomerList"></sub-customer>
		</div>
		--></div>
</template>

<script>
import ManagerInfo from '@/components/apply/user/store/ManagerInfo.vue';
import popupTemplate from '@/components/user/CustomerInfoLayer';
import AttachComponent from '@/components/attach/Attach';
import TermsTemplate from '@/components/apply/termsDetail.vue';
export default {
	components: { ManagerInfo, AttachComponent },
	data() {
		return {
			nextPayDateStr: '',
			autoExtensionCardList: [],
			autoExtension: false,
			membership: null,
			customer: null,
			accountInfoEdit: false,
			monthlyFee: 0,
			conversionProduct: {},
		};
	},
	async created() {
		// membership의 customer 정보 조회
		await this.searchMembership();
	},
	watch: {
		autoExtension() {
			if (!this.autoExtension) {
				this.cancelAutoExtensionCard();
			}
		},
	},
	computed: {
		payInfoVisible() {
			return (
				this.membership.membershipChargeType !== 'FREE' &&
				(this.autoExtension ||
					(!this.autoExtension &&
						this.membership.partnerSimpleModel.salesType !== 'RESELLER' &&
						this.membership.enableAccessStore))
			);
		},
	},
	methods: {
		openEditLayer() {
			const info = {
				id: this.customer.id,
				corporateName: this.customer.companyInfo.corporateName,
				ceoName: this.customer.companyInfo.ceoName,
				businessLicenseNum: this.customer.companyInfo.businessLicenseNum,
				businessNum: this.customer.companyInfo.businessNum,
				industryCategory: this.customer.companyInfo.industryCategory,
				businessCondition: this.customer.companyInfo.businessCondition,
				businessType: this.customer.companyInfo.businessType,
				companyAddress: this.customer.companyInfo.companyAddress,
				repNumber: this.customer.companyInfo.repNumber,
			};
			this.$modal.show(
				popupTemplate,
				{
					info: info,
				},
				{
					width: '520px',
					height: '500px',
					styles: 'overflow:inherit',
					draggable: true,
				},
				{
					closed: this.popupClosed,
				},
			);
		},
		popupClosed() {
			this.searchMembership();
		},
		isSAAS() {
			return this.membership.baseChannelInfoModel.baseChannelModel.serviceCategoryModel.serviceType == 'SAAS';
		},
		getTermsModels() {
			return this.membership.baseChannelInfoModel.baseChannelModel.termsSectionModel.termsModels;
		},
		async saveAccountInfo() {
			await this.$store.dispatch('UPDATECUSTOMER', this.customer);
			this.$ms.dialog.alert('저장 완료', '계좌 및 세금계산서 담당자 정보가 저장되었습니다.', () => {});
		},
		setNextPayDate(expiredDate) {
			const membershipEndDate = new Date(expiredDate);
			membershipEndDate.setDate(membershipEndDate.getDate() - 1);
			let year = new String(membershipEndDate.getFullYear());
			let month = new String(membershipEndDate.getMonth() + 1);
			let day = new String(membershipEndDate.getDate());
			if (month.length == 1) {
				month = '0' + month;
			}
			if (day.length == 1) {
				day = '0' + day;
			}
			this.nextPayDateStr = year + '년' + month + '월' + day + '일';
		},
		autoExtensionChange(autoExtension) {
			if (autoExtension) {
				this.$ms.dialog.confirm(
					'자동 연장 해지',
					'자동 연장을 해지하시겠습니까?' +
						'\n 해지 시 ' +
						this.membership.membershipBaseInfoModel.expirationDate +
						'일 까지 사용가능합니다.',
					() => {
						this.autoExtension = false;
						this.$ms.dialog.alert('자동 연장 해지', '자동 연장이 해지되었습니다.', () => {});
					},
					() => {
						return;
					},
				);
			} else {
				this.$ms.dialog.confirm(
					'자동 연장 신청',
					'자동 연장을 신청하시겠습니까?' + '\n 추가/연장페이지로 이동합니다.',
					() => {
						if (!this.membership.enableNextOrder) {
							this.$ms.dialog.alert(
								'서비스 추가/연장 불가',
								'현재 멤버십 전환 예정으로 서비스 추가/연장이 불가합니다. \n 신청하신 멤버십 전환 내역은 주문/구매내역에서 확인하실 수 있습니다. \n관련 문의사항은 영업담당자나 1577-3019로 문의주시기 바랍니다.',
								() => {},
							);
							return;
						}
						this.$router.push('/order/addExtension/autoExtension');
					},
					() => {
						return;
					},
				);
			}
		},
		async searchAutoExtensionCardList(customerId) {
			try {
				const obj = await this.$store.dispatch('SEARCHAUTOPAYMENTCARDINFO', customerId);

				if (obj) {
					if (!obj.lastCardNo) {
						obj['cardNumberDisplay'] = '***-****-****';
					} else {
						obj['cardNumberDisplay'] = '***-****-' + obj.lastCardNo;
					}
					this.autoExtensionCardList.push(obj);
				}
			} catch (e) {
				console.log(e);
			}
		},
		async cancelAutoExtensionCard() {
			await this.$store.dispatch('CANCELAUTOPAYMENTCARDINFO', this.membership.id);
			await this.searchMembership;
		},
		async saveManagerInfo() {
			const data = await this.$store.dispatch('UPDATECUSTOMER', this.customer);
		},
		closeLayer() {
			this.cardRegistrationFlag = false;
			this.searchAutoExtensionCardList(this.customer.id);
		},
		removeManager(index) {
			this.customer.managerList.splice(index, 1);
		},
		async searchMembership() {
			let data;
			//const { data } = await this.$store.dispatch('GETMEMBERSHIP', 36);
			// 사용자 수 기본, 추가 separation
			try {
				data = await this.$store.dispatch('GETMEMBERSHIP');
				if (!data) {
					const token = sessionStorage.getItem('userToken');
					if (token) {
						data = await this.$store.dispatch('GETMEMBERSHIP');
					}
				}
			} catch (e) {
				const token = sessionStorage.getItem('userToken');
				if (token) {
					data = await this.$store.dispatch('GETMEMBERSHIP');
				}
			}

			this.membership = data;
			this.autoExtension = data.autoExtension;
			this.customer = data.customer;
			this.customer.managerList.forEach((row) => {
				row['state'] = 'existing';
			});
			if (this.autoExtension) {
				await this.searchAutoExtensionCardList(this.customer.id); // 자동결제 카드 불러오기
				await this.setNextPayDate(this.membership.membershipBaseInfoModel.expirationDate);
				await this.setMonthlyFee();
			}
			if (this.customer.companyInfo.businessLicenses.length > 0) {
				this.$store.commit('setBusinessRegistration', this.customer.companyInfo.businessLicenses);
			}
		},
		showTaxInvoicePrivacyTerm() {
			const privacyTerms = this.getTermsModels().filter((obj) => obj.termsType == 'TERMS_OF_PRIVACY' && obj.useForm);
			let privacyInfoTerm = null;
			if (privacyTerms && privacyTerms.length > 0) {
				privacyInfoTerm = privacyTerms[0];
			}
			if (privacyInfoTerm == null) {
				this.$toasted.show('조회 가능한 약관이 없습니다.');
				return;
			}
			this.$modal.show(
				TermsTemplate,
				{
					info: privacyInfoTerm,
				},
				{
					width: '800px',
					height: '715px',
					draggable: true,
				},
			);
		},
		// 자동연장 case
		// 전환대상 o: 신요금제 멤버십을 기준으로 다시 계산한 결제예상금액을 가져온다.
		// 전환대상 x: 기존 멤버십의 결제예상금액을 가져온다.
		async setMonthlyFee() {
			await this.checkBaseChannelConvertable();
			if (this.conversionProduct.convertable) {
				const newMembership = await this.convertToNewMembership(this.conversionProduct.conversionId);
				this.monthlyFee = newMembership.convertedMonthlyFee;
			} else {
				this.monthlyFee = this.membership.monthlyFee;
			}
		},
		// 전환대상 요금제를 사용중인지 체크
		async checkBaseChannelConvertable() {
			const baseChannelId = this.membership.baseChannelInfoModel.baseChannelModel.id;
			this.conversionProduct = await this.$store.dispatch('CHECK_BASECHANNEL_IS_CONVERTABLE', baseChannelId);
		},
		// 임시로 만든 신요금제 멤버십 정보를 조회
		async convertToNewMembership(conversionId) {
			const conversionMembership = await this.$store.dispatch('GET_CONVERSION_MEMBERSHIP', {
				membershipId: this.membership.id,
				baseChannelId: conversionId,
			});
			return conversionMembership;
		},
	},
};
</script>

<style scoped>
.edit_position {
	vertical-align: 2px;
	margin-left: 5px;
	cursor: pointer;
}
</style>
